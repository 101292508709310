<template>
  <transition
    :tag="tag"
    v-bind="$attrs"
    name="fade"
    v-on="hooks">
    <slot></slot>
  </transition>
</template>

<script>
export default {
  name: "FadeTransition",
  props: {
    tag: {
      type: String,
      default: "div"
    },
    duration: {
      type: Number,
      default: 300
    },
    delay: {
      type: Number,
      default: 0
    },
    fixAfterEnterTo: {
      type: Boolean,
      default: false
    },
    crossFade: {
      type: Boolean,
      default: false
    }
  },
  emits: ["after-enter", "after-appear"],
  computed: {
    hooks() {
      return {
        enter: this.enter,
        leave: this.leave,
        afterEnter: this.afterEnter,
        afterAppear: this.afterAppear
      };
    }
  },
  methods: {
    leave(el) {
      el.style.transitionDuration = `${this.duration / 1000}s`;
      this.setAbsolutePosition(el);
    },
    enter(el) {
      el.style.transitionDuration = `${this.duration / 1000}s`;
      el.style.transitionDelay = `${this.delay / 1000}s`;
      this.setRelativePosition(el);
    },
    afterEnter(el) {
      if (
        this.fixAfterEnterTo &&
        el.dataset.fixAfterEnter &&
        el.style.display === "none"
      ) {
        el.style.removeProperty("display");
      }
      el.style.removeProperty("transition-delay");
      this.$emit("after-enter");
    },
    afterAppear() {
      this.$emit("after-appear");
    },
    setAbsolutePosition(el) {
      if (this.crossFade) {
        const element = el;
        element.style.position = "absolute";
        element.style.top = 0;
      }
    },
    setRelativePosition(el) {
      if (this.crossFade) {
        const element = el;
        element.style.position = "relative";
      }
    }
  }
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
